
import {
  SET_OPEN_COMPANY_PROFILE,
  SET_OPEN_SPONSORSHIP_PROFILE,
  SET_HISTORY_SPONSORSHIP_PROFILE,
  SET_OPEN_UNASSIGNED_PROFILE,
  SET_LEFT_PAGE,
  SET_OPEN_LOGIN,
  SET_OPEN_NEW_PASSWORD,
  SET_OPEN_SIGNUP,
  SET_OPEN_RESET_PASSWORD,
  SET_OPEN_SNACK,
  SET_SNACK_MSG,
  SET_LEFT_SELECTED_CATEGORY,
  SET_LEFT_SEARCH_VAL,
  SET_LEFT_BOOTHLIST,
  SET_BOOKMARD_UPDATED,
  SET_PAN_FLAG,
  SET_LOCK_FLAG,
  RESET,
  SET_OPEN_BOOTHLIST,
  SET_OPEN_FILE_NAME_MODAL,
  SET_OPEN_SETBOOTH_MODAL,
  SET_OPEN_TABLE_MODAL,
  SET_OPEN_ASSIGN_MODAL,
  SET_OPEN_BOOTH_DRAWER,
  CLOSE_SNACK,
  OPEN_SNACK,
  SNACK_MSG,
  SET_TOP_MENU,
  SET_EXHIBITOR_SEARCH_KEY,
  SET_OPEN_RELEASE_MODAL,
  SET_OPEN_TRANSFER_MODAL,
  SET_EXHIBITOR_UPDATED,
  SET_CUSTOM_STYLE,
  SET_OTP_FLAG,
  SET_OPEN_IMPORT_MODAL,  
  SET_OPEN_COMPANY_MODAL,
  SET_EDIT_COMPANY,
  SET_MOUSE_HOVER_ENABLE,
} from '../actions/ui';

var initVal = {
  openImportModal:false,
  openCompanyProfile:false,
  openSponsorshipProfile:false,
  historySponsorshipProfile:false,
  openUnassignedProfile:false,
  leftPage:'home',
  otpFlag: false,
  openLogin:false,
  openSignup: false,
  openNewPassword: false,
  snackMsg:'',
  leftSelectedCategory: '',
  leftSearchVal: '',
  leftBoothList:[],
  lockFlag:1,
  openBoothList:false,
  openFilenameModal:false,
  menuList:[],
  // exhibitorSearchKey:false,
  exhibitorUpdated:false,
  openCompanyModal:false,
  editCompany:null,
  mouseHoverEnable:true,
  customStyles:{

  }
}
export default function quote(state = initVal, {type, payload}) {
  switch (type) {
    case CLOSE_SNACK:
      return {
        ...state,
        openSnack: false,
        snackMsg: '',
    }
    case OPEN_SNACK:
      return {
        ...state,
        openSnack: 1
    }
    case SNACK_MSG:
      return {
        ...state,
        snackMsg: payload,
    }
    case SET_OPEN_IMPORT_MODAL:
      console.log("SET_OPEN_IMPORT_MODAL", payload)
      return {
        ...state,
        openImportModal: payload,
    }
    case SET_OPEN_BOOTH_DRAWER:
      return {
        ...state,
        openBoothDrawer: payload,
    }
    case SET_OPEN_ASSIGN_MODAL:
      return {
        ...state,
        openSetAssignModal: payload,
    }
    case SET_OPEN_RELEASE_MODAL:
      return {
        ...state,
        openReleaseModal: payload,
    }
    case SET_EXHIBITOR_UPDATED:
      return {
        ...state,
        exhibitorUpdated: payload,
    }
    case SET_OPEN_TRANSFER_MODAL:
      return {
        ...state,
        openTransferModal: payload,
    }
    case SET_OPEN_TABLE_MODAL:
      return {
        ...state,
        openSetTableModal: payload,
    }
    case SET_OPEN_SETBOOTH_MODAL:
      return {
        ...state,
        openSetBoothModal: payload,
    }
    case SET_OPEN_FILE_NAME_MODAL:
      return {
        ...state,
        openFilenameModal: payload,
    }
    case SET_OPEN_BOOTHLIST:
      return {
        ...state,
        openBoothList: payload,
    }
    case SET_EXHIBITOR_SEARCH_KEY:
      return {
        ...state,
        exhibitorSearchKey: payload,
    }
    case RESET:
      console.log("RESET", payload)
      return initVal
    case SET_OPEN_COMPANY_PROFILE:
      return {
        ...state,
        openCompanyProfile: payload,
    }
    case SET_OPEN_SPONSORSHIP_PROFILE:
      return {
        ...state,
        openSponsorshipProfile: payload,
    }    
    case SET_HISTORY_SPONSORSHIP_PROFILE:
      return {
        ...state,
        historySponsorshipProfile: payload,
    }    
    case SET_OPEN_COMPANY_MODAL:
      return {
        ...state,
        openCompanyModal: payload,
    }
    case SET_EDIT_COMPANY:
      return {
        ...state,
        editCompany: payload,
    }   
    case SET_MOUSE_HOVER_ENABLE:
      return {
        ...state,
        mouseHoverEnable: payload,
    }    
    case SET_OPEN_UNASSIGNED_PROFILE:
      return {
        ...state,
        openUnassignedProfile: payload,
    }    
    case SET_LEFT_PAGE:
      return {
        ...state,
        leftPage: payload,
    }
    case SET_OPEN_LOGIN:
      return {
        ...state,
        openLogin: payload,
    }
    case SET_OTP_FLAG:
      return {
        ...state,
        otpFlag: payload,
    }
    case SET_TOP_MENU:
      return {
        ...state,
        menuList: payload,
    }
    case SET_OPEN_NEW_PASSWORD:
      return {
        ...state,
        openNewPassword: payload,
    }
    case SET_OPEN_SIGNUP:
      return {
        ...state,
        openSignup: payload,
    }
    case SET_OPEN_RESET_PASSWORD:
      return {
        ...state,
        openResetPassword: payload,
    }    
    case SET_OPEN_SNACK:
      return {
        ...state,
        openSnack: payload,
    }
    case SET_SNACK_MSG:
      return {
        ...state,
        snackMsg: payload,
    }
    case SET_LEFT_SELECTED_CATEGORY:
      return {
        ...state,
        leftSelectedCategory: payload,
    }
    case SET_LEFT_SEARCH_VAL:
      return {
        ...state,
        leftSearchVal: payload,
    }
    case SET_LEFT_BOOTHLIST:
      return {
        ...state,
        leftBoothList: payload,
    }
    case SET_BOOKMARD_UPDATED:
      return {
        ...state,
        bookmarkUpdated: payload,
    }
    case SET_PAN_FLAG:
      return {
        ...state,
        panFlag: payload,
      }
    case SET_LOCK_FLAG:
      return {
        ...state,
        lockFlag: payload,
      }
    case SET_CUSTOM_STYLE:
      return {
        ...state,
        customStyles: {...payload, loaded: true},
      }
      default:
          return state;
  }
}