import React ,{ useEffect, useRef, useState } from 'react';
import { connect } from 'react-redux'
import './App.css';
import MainViewer from './viewer/MainViewer';
import Button from '@mui/material/Button';
import Box from '@mui/material/Box';
import { styled } from '@mui/material/styles';
import Paper from '@mui/material/Paper';
import Grid from '@mui/material/Grid';
import { setAuthInfo, setLoading, setBookmarkList, setExhibitorList} from './actions/main.js'
import { setOpenCompanyProfile } from './actions/ui.js'
import { setCustomStyles } from './actions/ui.js'
import { setOpenLogin } from './actions/ui.js'
import { parseToken, getTokenCookie, getTokenPlanner, setCookie, getUrlToken } from './utils/Common.js'
import LoadingOverlay from 'react-loading-overlay';
import ScaleLoader from 'react-spinners/ScaleLoader'
import LeftProfile from  './viewer/component/LeftProfile.js';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import CloseIcon from '@mui/icons-material/Close';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import { useParams } from 'react-router-dom'
import {
  BrowserRouter as Router,
  Link,
  useLocation
} from "react-router-dom";
import qs, { parse } from 'qs'
import axios from 'axios';
import { makeStyles } from '@mui/styles';
import { API_URL, ESHOW_URL } from './config.js'
import axiosInstance from './utils/axiosInstance.js'
function useQuery() {
    const { search } = useLocation();
  
    return React.useMemo(() => new URLSearchParams(search), [search]);
}

let cart_id = '';
var g_authInfo = false
let cardForm = false
function Index(props) {
  const { id1, id2, id3, id4, id5, id6 } = useParams();
  let query = useQuery();
  const [dataFetched, setDataFetched] = useState(false);
  const [loadingFlag, setLoadingFlag] = React.useState(false);
  const [globalPayToken, setGlobalPayToken] = React.useState(false);
  const [salesOrder, setSalesOrder] = useState(false)
  const [errorMessage, setErrorMessage] = React.useState(false);
  const [autoCombine, setAutoCombine] = React.useState(false)
  const [canvasWidth, setCanvasWidth] = React.useState(false)
  
  const divRef = useRef(null);
  
 
  const useStyles = makeStyles((theme) => ({
    searchBtn:{
      backgroundColor: props.ui?.customStyles?.['search-button-bg-color']?(props.ui?.customStyles['search-button-bg-color']+"!important"):'#1565c0',
      color: props.ui?.customStyles?.['search-button-label-color']?(props.ui?.customStyles['search-button-label-color']+"!important"):'#1565c0',
    },
    bubble:{
      color:props.ui?.customStyles?.['bubble-label-color']?(props.ui?.customStyles['bubble-label-color']+"!important"):'white',
      backgroundColor:props.ui?.customStyles?.['bubble-bg-color']?(props.ui?.customStyles['bubble-bg-color']+"!important"):'#1565c0',
    }
  }));
  const classes = useStyles();

  const processAuth = async ()=>{      
    var tokenInfo = await parseToken(query)  
    await fetchCustomStyles();          
    var show_key = tokenInfo.show_key?tokenInfo.show_key:'';
    var user_key = tokenInfo.user_key?tokenInfo.user_key:'';
    var BOOTH_PRICING = tokenInfo.BOOTH_PRICING?tokenInfo.BOOTH_PRICING:'';
    var SHOPPING_CART = tokenInfo.SHOPPING_CART?tokenInfo.SHOPPING_CART:'';
    var SALES = tokenInfo.SALES
    props.setAuthInfo({show_key, user_key, SALES,BOOTH_PRICING,SHOPPING_CART, authenticated: (tokenInfo.show_key && tokenInfo.user_key?3:2), tokenInfo})
    if(!(tokenInfo.show_key && tokenInfo.user_key)){ 
        return
    }
  }

  const fetchCustomStyles = async ()=>{
    if(query.get('sales_map')) {                        
      props.setCustomStyles({})
      return;
    }
    console.log("fetchCustomStyles")
    var url = `${ESHOW_URL()}?method=getCSS&token=${getTokenCookie()}`
    var formdata = 
    {  
        query:`${ESHOW_URL()}?method=getCSS&token=${getTokenCookie()}`                
    }
    var data = qs.stringify({
        request: JSON.stringify(formdata)
    });
    const options = {
        headers: {"content-type": 'application/x-www-form-urlencoded'}
    }
    const response = await axiosInstance.get(url)
    if(response.status == 200){
        var data = response.data
        if(data.SUCCESS == true){
            if(data.CSS){
                console.log("result.CSS", data.CSS)                    
                props.setCustomStyles(data.CSS)
            }
        }
    }
    else{
        console.log("")
    }
  }

  const getTotal=()=>{    
    var total = 0;
    let sales = salesOrder?.SALES
    for(let i = 0; i < sales?.length; i++){
      let sale = sales[i]
      total+= (`${sale?.AMOUNT}`?.replace("$","").replaceAll(",",""))*(sale?.QTY);
    }
    return total
  }

  useEffect(() => {
    if(globalPayToken){
      cardForm = window.GlobalPayments.ui.form({
        fields: {
           "card-number": {
              placeholder: "•••• •••• •••• ••••",
              target: "#card-number"
           },
           "card-expiration": {
              placeholder: "MM / YYYY",
              target: "#card-expiration"
           },
           "card-cvv": {
              placeholder: "•••",
              target: "#card-cvv"
           },
           "card-holder-name": {
              placeholder: "Jane Smith",
              target: "#card-holder-name"
           },
           "submit": {
              target: "#submit-button",
              value: "Pay Now",
              label: "test"
           }
        },
        styles: {
          // add custom CSS styling
          "input[type=text]": {
            "margin-bottom": "20px",
            "padding": "12px",
            "border": "1px solid #ccc",
            "border-radius": "10px",
          },
            
            //adding focus on the fields when selected
          "input[type=text]:focus-visible, input[type=tel]:focus-visible": {
            "outline": "none !important",
            "border": "1px solid #71C5E8",
            "box-shadow": "0 0 4px 0 #71C5E8 inset",
          },
        
            //adding a radius on all number inputs
          "input[type=tel]": {
            "margin-bottom": "20px",
            "padding": "12px",
            "border": "1px solid #ccc",
            "border-radius": "10px"
          },
            
            //adding focus on the fields when selected
          "button[type=button]:focus-visible": {
            "background-color": "#71C5E8",
            "outline": "none !important",
            "border": "1px solid gray",
            "box-shadow": "0 -1px 4px 0 gray inset",
          },
        
            //adding styling to the submit button
          "button[type=button]": {
            "background-color": "#00AEEF",
            "color": "white",
            "padding": "12px",
            "margin": "10px",
            "border": "none",
            "border-radius": "10px",
            "cursor": "pointer",
            "font-size": "17px",
          },
        
            //adding hover color
          "button[type=button]:hover": {
            "background-color": "#71C5E8",
          }
        }
      });
      // document.getElementById("submit-button").innerText = "Pay Now";

      window.GlobalPayments.configure({
        accessToken: globalPayToken.token,
        apiVersion: "2021-03-22",
        env: "sandbox", // sandbox or "production"
        // merchantId:'MER_7e3e2c7df34f42819b3edee31022ee3f',
      });
      // method to notify that hosted fields have been initialized
      cardForm.ready(() => {
          console.log("Registration of all credit card fields occurred");
          
          //TODO: Add your successful message
      });
      
      // appending the token to the form as a hidden field and
      // submitting it to the server-side
      cardForm.on("token-success", async (resp) => {
          console.log("token-success", resp)
          // add payment token to form as a hidden input
          const token = document.createElement("input");
          // token.type = "hidden";
          // token.name = "payment-reference";
          // token.value = resp.paymentReference;
          // const form = document.getElementById("payment-form");
          // form.appendChild(token);
          let cartList = props.main.cartList
          console.log("cartList", cartList)
          let total = getTotal();

          var formdata = 
            {
              paymentId:resp.paymentReference,
              paymentAmount: total,
              token: globalPayToken.token
            }
            var data = qs.stringify({
                request: JSON.stringify(formdata)
              });
            const options = {
                headers: {"content-type": 'application/x-www-form-urlencoded'}
            }
            setLoadingFlag(true)
            var response = await axiosInstance.post(`/processtransactions?token=${getTokenCookie()}`, data, options);
            if(response.status == 200){
              let data = response.data;
              if(data.status == "success"){
                // setSnackMsg("Payment succeed");
                // console.log("Payment succeed", data)
                // setPaymentResult(data)
                // setOpenSnack(true);
                setLoadingFlag(false)
                onOk()
              }
              else{
                setErrorMessage("Payment failed");
                setLoadingFlag(false)
              }
            }
            console.log("response", response)
            // setSnackMsg(reason);
            // setOpenSnack(true);
          // form.submit();
      });
    
      // add error handling if token generation is not successful
      cardForm.on("token-error", (resp) => {
          // TODO: Add your error handling
          console.log("token-error", resp)
          if(resp.error && resp.reasons[0]){
            let reason =  resp.reasons[0].message;
            setErrorMessage(reason);
          }
          setLoadingFlag(false)
      });
      cardForm.on("submit", "click", (resp) => {
        setLoadingFlag(true)
      });
    
      // field-level event handlers. example:
      cardForm.on("card-number", "register", () => {
          console.log("Registration of Card Number occurred");
              
      });
    }
  }, [globalPayToken])

  const getAccessToken = ()=>{
    axiosInstance.get(`/getaccesstoken?token=${getTokenCookie()}`).then(res=>{
      if(res.data){
        console.log(res.data)
        if(res.data.status == 'success'){
          setGlobalPayToken(res.data.message)
        }
        else{
          setGlobalPayToken(false)
        }
      }
    }).catch(error => {
        console.log("error", error);
    })
  }

  const onOk = ()=>{

  }
  const fetchData = (cart_id)=>{
    props.setLoading(true)
    var token = getTokenCookie();   
    try{     
        var _token = getTokenPlanner();
        if(_token){
          token = _token
        }
    }
    catch(e){
      console.log("token parse", e)
    }
    var url = `${ESHOW_URL()}?method=getShoppingCart&token=${token}&cart_id=${cart_id}`
    axiosInstance.get(url).then(res=>{
        const result = res.data;
        if(result.SUCCESS || true){
          setSalesOrder(result.SALES_ORDER)
        }
        else{
        }
        setDataFetched(true)
        props.setLoading(false)
    }).catch(error => {
      props.setLoading(false)
    })  
  }

  const queryString = window.location.search;
  const urlParams = new URLSearchParams(queryString);
  var show_key = urlParams.get('show_key')      
  var urlToken = getUrlToken()
  useEffect(() => {
    if(props.urlToken){
      var public_token = `/${id1}`
        if(id2){
          public_token = `${public_token}/${id2}`
        }
        if(id3){
          public_token = `${public_token}/${id3}`
          cart_id = id4
        }
        if(id4){
          public_token = `${public_token}/${id4}`
          cart_id = id5
        }
        if(id5){
          public_token = `${public_token}/${id5}`
        }
        setCookie('V3PUBLICMAP', public_token)
    }
    setCanvasWidth(divRef.current.offsetWidth)
    fetchData(cart_id)
    getAccessToken()
  }, [props.main[`planner-${urlToken}`], id5, id4, id3, id2, id1])

 
  let total = getTotal();
  let selectedBooths = [];
  selectedBooths = []

  return (
    <Box id="xhbt-main-container" className="App Client">
        <Grid container spacing={2}>
            <Grid item xs={12} sm={7}>
              <Box>
                <Table className="color-table" sx={{ minWidth: 650 , marginTop:'15px'}} aria-label="simple table">
                  <TableHead>
                    <TableRow>
                      <TableCell colSpan={2}>Company Information</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    <TableRow>
                      <TableCell className='v-th' width="200px">Order Number</TableCell>
                      <TableCell>{salesOrder?.ORDER_NUMBER}</TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell className='v-th'>Company Name</TableCell>
                      <TableCell>{salesOrder?.COMPANY_NAME}</TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell className='v-th'>First Name</TableCell>
                      <TableCell>{salesOrder?.CONTACTS?.[0]?.FIRST_NAME}</TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell className='v-th'>Last Name</TableCell>
                      <TableCell>{salesOrder?.CONTACTS?.[0]?.LAST_NAME}</TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell className='v-th'>Email</TableCell>
                      <TableCell>{salesOrder?.CONTACTS?.[0]?.EMAIL}</TableCell>
                    </TableRow>
                  </TableBody>
                </Table>
              </Box>
              <Box ref={divRef} mt={2}>
                {
                  Boolean(canvasWidth)&&
                  <MainViewer {...props} width={canvasWidth} height="600" cartPage = {true} selectedBooths={selectedBooths}/>
                }
              </Box>
            </Grid>
            <Grid item xs={12} sm={5}>
              <Box>
                <Table className="color-table small" sx={{ minWidth: 650 , marginTop:'15px'}} aria-label="simple table">
                  <TableHead>
                    <TableRow>
                      <TableCell width="50px">Qty</TableCell>
                      <TableCell>Product</TableCell>
                      <TableCell width="120px">Unit</TableCell>
                      <TableCell width="120px">Price</TableCell>
                      <TableCell width="50px">Delete</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {
                      salesOrder?.SALES?.map((sale, index)=>{
                        return(
                          <TableRow key={index}>
                            <TableCell>{sale?.QTY}</TableCell>
                            <TableCell>{sale?.TITLE}</TableCell>
                            <TableCell>${sale?.AMOUNT?.toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ",")}</TableCell>
                            <TableCell>${(sale?.AMOUNT*sale?.QTY).toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ",")}</TableCell>
                            <TableCell sx={{textAlign:'center'}}>
                                  <CloseIcon color='#bd1616' fontSize="small" />
                            </TableCell>
                          </TableRow>
                        )
                      })
                    }
                  </TableBody>
                </Table>
              </Box>
              {
                salesOrder?.SALES?.length > 1 &&
                <FormGroup>
                  <FormControlLabel className='auto-combine-label' control={<Checkbox defaultChecked checked={autoCombine} onChange={(e)=>setAutoCombine(e.target.checked)}/>} label="Auto-Combine Booths" />
                </FormGroup>
              }
              <Box mt={3}>
                <Box display={'flex'} style={{justifyContent:'space-between', maxWidth:'420px', margin: 'auto'}}>
                  <img width='100px' height='50px' src='/asset/visa.png'/>
                  <img width='100px' height='50px' src='/asset/mastercard.png'/>
                  <img width='100px' height='50px' src='/asset/american_express.png'/>
                  <img width='100px' height='50px' src='/asset/discover.png'/>
                </Box>
                <Box mt={2}>
                  <form className='payment-form' style={{textAlign:'left', padding:'16px'}} id="payment-form" method="post" action="process_payment">
                    <h3 style={{marginBottom:6}}>Payment Amount</h3>
                    <div className="price">${total?.toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ",")}</div>
                    <label for="card-number">Card Number</label>
                    <div id="card-number"></div>
                    <label for="card-expiration">Expiry Date</label>
                    <div id="card-expiration"></div>
                    <label for="card-cvv">Security Code</label>
                    <div id="card-cvv"></div>
                    <label for="card-holder-name">Cardholder Name</label>
                    <div id="card-holder-name"></div>
                    <div id="submit-button"></div>
                  </form>
                </Box>
              </Box>
            </Grid>
        </Grid>
      
      <LeftProfile groupOptions = {[]}/>
      {props.ui?.customStyles?.['loaded'] &&
      <LoadingOverlay
            active={loadingFlag}
            fadeSpeed = {100}
            styles={{
                overlay: (base) => ({
                ...base,
                fontSize:'18px',
                color: 'rgb(5, 37, 51)',
                // background: 'rgb(229 229 229 / 92%)',
                background: 'transparent',
                position:'fixed',
                top: '49px',
                zIndex:1000000000
                })
            }}
            spinner = {<ScaleLoader color={props.ui?.customStyles?.['spinner-color']?props.ui?.customStyles['spinner-color']:'#1976d2'} radius={2.5} margin={2.5} height={40} width={5}/>}
            // spinner
            // text='Loading ...'
            >
        </LoadingOverlay> 
        }
        {errorMessage && 
        <Dialog
          open={Boolean(errorMessage)}
          onClose={()=>setErrorMessage(false)}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
          maxWidth='xs'
        >
          <DialogTitle sx={{color:'white', background:'#b50d0d'}} id="alert-dialog-title">
            Error
          </DialogTitle>
          <DialogContent>
            <DialogContentText id="alert-dialog-description">
              <Box mt={2}>
                {errorMessage}
              </Box>
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={()=>setErrorMessage(false)}>Close</Button>
          </DialogActions>
        </Dialog>
        }
    </Box>
  );
}


const mapStateToProps = (state) => {
  return {
    main: state.main,
    booth_history: state.booth_history.present,
    ui: state.ui,
    cart: state.cart,
  };
};

const mapDispatchProps = (dispatch) => {
  return {    
      setCustomStyles: data=>dispatch(setCustomStyles(data)),
      setAuthInfo:  data=>dispatch(setAuthInfo(data)), 
      setLoading:  data=>dispatch(setLoading(data)),
      setBookmarkList:  data=>dispatch(setBookmarkList(data)),
      setOpenLogin: value=>dispatch(setOpenLogin(value)) ,
      setOpenCompanyProfile:  data=>dispatch(setOpenCompanyProfile(data)),
      setExhibitorList:  data=>dispatch(setExhibitorList(data)),
  };
};

export default connect(mapStateToProps, mapDispatchProps)(Index);
